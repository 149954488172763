const appConfig = {
  "version": "1.0.1-release+codebuild.218",
  "stage": {
    "name": "release",
    "awsAccountId": "106651054130",
    "awsProfileName": "api-gateway-qa",
    "branchName": "release",
    "branchSafeName": "release",
    "branchWebUrl": "https://github.mit.edu/ist-org/voip-web/tree/release",
    "isCoreBranch": true
  },
  "amplify": {
    "userPoolId": "us-east-1_14cFcpuS8",
    "userPoolWebClientId": "7iapvj75crn79593bjgphgfdog",
    "oauth": {
      "domain": "atlas-auth-test.mit.edu",
      "scope": [
        "openid",
        "profile",
        "voip/user",
        "digital-id/user",
        "digital-id/search"
      ],
      "redirectSignIn": "https://voip-test.mit.edu",
      "redirectSignOut": "https://voip-test.mit.edu/logout",
      "responseType": "code",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  },
  "signInProvider": "Touchstone",
  "api": {
    "hostname": "https://api-test.mit.edu",
    "paths": {
      "network-infrastructure": "voip-v2",
      "digital-id": "digital-id-v1"
    }
  }
};
export default appConfig;